<template>
	<div>
		<b-container>
			<b-row>
				<b-col class="pageTitle">
					Magic Fox Videos
				</b-col>
			</b-row>
			<b-row cols-sm class="contentRow">
				<b-col sm="6">
					<LazyYoutube src="https://www.youtube.com/watch?v=TDXXWXgae5M" />
				</b-col>
				<b-col sm="6" class="contentLeft">
					<div class="contentTitle">Our First Donation</div>
					<div class="contentText">For our very first donation, we were able to give roughly $975 (after bridging to ETH and paying gas). That's almost 10,000 meals provided to those in need.</div>
				</b-col>
			</b-row>
			<b-row cols-sm class="contentRow">
				<b-col sm="6" class="contentLeft">
					<div class="contentTitle">Introduction to EMF</div>
					<div class="contentText">Here is a quick introduction video to help explain what Ellery's Magic Foxes are all about!</div>
				</b-col>
				<b-col sm="6">
					<LazyYoutube src="https://www.youtube.com/watch?v=9r5a89vNRXg" />
				</b-col>
			</b-row>
			<b-row cols-sm class="contentRow">
				<b-col sm="6">
					<LazyYoutube src="https://www.youtube.com/watch?v=Py5JW5J8afg" />
				</b-col>
				<b-col sm="6" class="contentLeft">
					<div class="contentTitle">Feeding America</div>
					<div class="contentText">Check out this video to see exactly how Feeding America is making a real difference for people in need</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
export default {
	name: 'aboutMagicFoxes',
}
</script>