import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import MagicFoxes from './MagicFoxes.vue'
import LazyYoutube from 'vue-lazytube'

Vue.config.productionTip = false
Vue.use(LazyYoutube);

new Vue({
	render: h => h(MagicFoxes),
}).$mount('#app')
