<template>
	<div>
		<b-container>
			<b-row>
				<b-col class="pageTitle">
					Ellery's Magic Foxes
				</b-col>
			</b-row>

			<b-row col-sm class="contentRow">
				<b-col sm="6" class="contentLeft">
					<div class="contentTitle">What are Ellery's Magic Foxes?</div>
					<div class="contentText">Ellery's Magic Foxes are meant to bring a smile to the face of anyone who looks at them.</div>
					<div class="contentText">But more than that, they are a way to help raise money for a good cause.</div>
					<div class="contentText">100% of the money raised from the sale of these NFTs will go towards <a href="https://www.feedingamerica.org/" target="_new">Feeding America</a></div>
					<div class="contentText">You can view the whole collection over on <a href="https://opensea.io/collection/ellery-s-magic-foxes" target="_new">OpenSea</a></div>
				</b-col>
				<b-col sm="6" class="contentRight">
					<b-img src="DemoFoxes.gif" rounded="circle" thumbnail class="demoImage" fluid style="margin-top: 10px;"/><br /><br />
				</b-col>
			</b-row>

			<b-row class="contentRow">
				<b-col class="contentLeft">
					<div class="contentTitle">How will the donations work?</div>
					<div class="contentText">All of the BNB that gets collected will be sent to the Magic Foxes deployer wallet<br />
					<a href="https://bscscan.com/address/0x18A8fA6Dbd0f7c7723AbC7c16794A31b59814172" target="_new">https://bscscan.com/address/0x18A8fA6Dbd0f7c7723AbC7c16794A31b59814172</a></div>
					<div class="contentText">Roughly every 14 days, we will take the BNB collected and convert it to ETH on the Ethereum network, keeping it in the same wallet address<br />
					<a href="https://etherscan.io/address/0x18A8fA6Dbd0f7c7723AbC7c16794A31b59814172" target="_new">https://etherscan.io/address/0x18A8fA6Dbd0f7c7723AbC7c16794A31b59814172</a></div>
					<div class="contentText">The reason for this is that Feeding America has a page that will let you donate with crypto, but only accepts certain tokens.</div>
					<div class="contentText">By swapping first to Ethereum, we can donate the proceeds completely on the blockchain, for maximum transparency and accountability.</div>
				</b-col>
			</b-row>

			<b-row cols-sm class="contentRow">
				<b-col sm="6" class="contentLeft">
					<b-img src="kyc.jfif" rounded thumbnail class="demoImage" fluid style="margin-top: 10px;"/><br /><br />
				</b-col>
				<b-col sm="6" class="contentLeft">
					<div class="contentTitle">Your trust is very important to us</div>
					<div class="contentText">Asking people to buy our NFTs on the promise that we will actually donate the money is a level of trust we want people to be comfortable with.</div>
					<div class="contentText">To that end, we went through the KYC process with AnonyDoxx, to ensure maximum accountability.</div>
					<div class="contentText">You can view their official tweet here: <a href="https://twitter.com/AnonyDoxx/status/1655667578239610885" target="_new">https://twitter.com/AnonyDoxx/status/1655667578239610885</a></div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<style>

</style>

<script>
export default {
	name: 'aboutMagicFoxes',
}
</script>