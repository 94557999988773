<template>
	<div>
		<b-container>
			<b-row>
				<b-col class="pageTitle">
					About Ellery's Magic Foxes
				</b-col>
			</b-row>
			<b-row cols-sm class="contentRow">
				<b-col class="contentLeft">
					<div class="contentTitle">Who is Ellery?</div>
					<div class="contentText">Ellery is a vibrant 8 year old who has a passion for making people happy.</div>
					<div class="contentText">Everything she does in her life is in an effort to make things just a bit better for those around her.</div>
				</b-col>
				<b-col sm="6" class="contentRight">
					<b-img src="ellery.png" rounded thumbnail class="demoImage" fluid style="margin-top: 10px;"/>
				</b-col>
			</b-row>
			<b-row cols-sm class="contentRow">
				<b-col sm="6" class="contentRight">
					<b-img src="kevin.png" rounded thumbnail class="demoImage" fluid style="margin-top: 10px;"/>
				</b-col>
				<b-col sm="6" class="contentLeft">
					<div class="contentTitle">Who is Kevin?</div>
					<div class="contentText">Kevin Remer is Ellery's dad, and a founding member of <a href="https://cryptolic.org" target="_new">Cryptolic</a>.</div>
					<div class="contentText">As a full service developer, Kevin has deployed over 100 smart contracts, dozens of dapps and helped advise numerous crypto projects on multiple chains.</div>
				</b-col>
			</b-row>
			<b-row class="contentRow">
				<b-col class="contentLeft">
					<div class="contentTitle">Why are you doing this?</div>
					<div class="contentText">A while back there was a food drive at my (Kevin) day job. Ellery asked why we were buying so much food that we were just going to give away. I explained to her that there are some people who have a hard time getting enough food to eat. Her mother and I were both at that point at differing times in our lives, so we feel especially driven to give back in any capacity, whenever we can. Knowing that some people don't have enough food to eat really stuck with Ellery, and she wanted to help too.</div>
					<div class="contentText">While watching YouTube videos on her phone one day, Ellery watched a few on NFTs, and she saw how people were making money from their artwork. She knew that I did "crypto stuff", and asked me if she could sell NFTs to make money and buy food for hungry people. I thought it was a wonderful idea.</div>
					<div class="contentText">During the process of coming up with ideas for artwork, Ellery went with her mom to her uncle's house to help paint cabinets. In his back yard, there were a family of foxes that Ellery just watched play all day long. When she got home, she told me all about them and wanted to make NFTs of the foxes. And that is how we arrived at Ellery's Magic Foxes!</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
export default {
	name: 'aboutEMF',
}
</script>