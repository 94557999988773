<template>
	<div>
		<b-card class="mainDisplay" title="Card Title" no-body>
			<b-card-header header-tag="nav">
				<b-navbar toggleable="lg" card-header pills>
					<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

					<b-collapse id="nav-collapse" is-nav>
						<b-navbar-nav class="ml-auto">
							<b-nav-item class="navLinks" to="#/">Home</b-nav-item>
							<b-nav-item class="navLinks" to="#/mint">Mint NFT</b-nav-item>
							<b-nav-item class="navLinks" to="#/about">About EMF</b-nav-item>
							<b-nav-item class="navLinks" to="#/videos">Videos</b-nav-item>
						</b-navbar-nav>
					</b-collapse>

					<a id="follow-button" href="https://twitter.com/EllerysFoxes?ref_src=twsrc%5Etfw" class="twitterButton" target="_new"><i></i> Follow @EllerysFoxes</a>
				</b-navbar>
			</b-card-header>

			<b-card-body class="text-center">
				<component :is="currentView" />
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
import aboutMagicFoxes from './aboutMagicFoxes.vue'
import mintMagicFoxes from './mintMagicFoxes.vue'
import videoMagicFoxes from './videoMagicFoxes.vue'
import aboutEMF from './aboutEMF.vue'

const routes = {
	'/': aboutMagicFoxes,
	'/mint': mintMagicFoxes,
	'/videos': videoMagicFoxes,
	'/about': aboutEMF
}

export default {
	data() {
		return {
			currentPath: window.location.hash
		}
	},
	computed: {
		currentView() {
			return routes[this.currentPath.slice(1) || '/']
		}
	},
	mounted() {
		window.addEventListener('hashchange', () => {
			this.currentPath = window.location.hash
		})
	}
}
</script>