<template>
	<div id="MagicFoxes">
		<navMagicFoxes />
	</div>
</template>

<script>
import navMagicFoxes from './components/navMagicFoxes.vue'

export default {
	name: 'MagicFoxes',
	components: {
		navMagicFoxes
	}
}
</script>

<style>
.twitterButton, .twitterButton:hover {
    position: relative;
    height: 20px;
    box-sizing: border-box;
    padding: 1px 12px 1px 12px;
    background-color: #1d9bf0;
    color: #fff;
    border-radius: 9999px;
    font-weight: 500;
    cursor: pointer;
    height: 28px;
    border-radius: 9999px;
    padding: 1px 12px 1px 12px;
	text-decoration: none;
}
.twitterButton i {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 14px;
    height: 14px;
    background: transparent 0 0 no-repeat;
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h72v72H0z%22%2F%3E%3Cpath%20class%3D%22icon%22%20fill%3D%22%23fff%22%20d%3D%22M68.812%2015.14c-2.348%201.04-4.87%201.744-7.52%202.06%202.704-1.62%204.78-4.186%205.757-7.243-2.53%201.5-5.33%202.592-8.314%203.176C56.35%2010.59%2052.948%209%2049.182%209c-7.23%200-13.092%205.86-13.092%2013.093%200%201.026.118%202.02.338%202.98C25.543%2024.527%2015.9%2019.318%209.44%2011.396c-1.125%201.936-1.77%204.184-1.77%206.58%200%204.543%202.312%208.552%205.824%2010.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163%200%206.345%204.513%2011.638%2010.504%2012.84-1.1.298-2.256.457-3.45.457-.845%200-1.666-.078-2.464-.23%201.667%205.2%206.5%208.985%2012.23%209.09-4.482%203.51-10.13%205.605-16.26%205.605-1.055%200-2.096-.06-3.122-.184%205.794%203.717%2012.676%205.882%2020.067%205.882%2024.083%200%2037.25-19.95%2037.25-37.25%200-.565-.013-1.133-.038-1.693%202.558-1.847%204.778-4.15%206.532-6.774z%22%2F%3E%3C%2Fsvg%3E);
}
.contentLeft, .contentRight {
	font-size: 125%;
}
.contentLeft {
	text-align:left;
}
.contentRight {
	text-align:right;
}
.contentTitle {
	font-weight: bold;
	padding-bottom: 15px;
	font-style: italic;
}
.contentText {
	margin-bottom: 15px;
}
.contentRow {
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: solid #e6e6e6;
}
.navLinks a {
	font-weight: bold;
	color: rgb(134, 122, 248);
	font-size: 150%;
	text-shadow: 1px 1px #000;
}
.navLinks a:hover {
	color: #a0a;
}
.demoImage {
	background-color: #666;
}
.pageTitle {
	font-weight: bold;
	font-size: 150%;
	margin-bottom: 20px;
	font-family: 'Comic Sans MS';
	color: rgb(243, 96, 211);
	text-shadow: 1px 1px 3px rgb(134, 122, 248);
}
.buttonText {
	color: #3a3a3a;
	margin-top: 20px;
	background-color: #E5BA73;
}
.txAlertBox {
	position: fixed;
	top: 10%;
	right: 0;
	width: 250px;
	z-index: 2
}
.loadingWindow {
	display: none;
	position: fixed;
	z-index: 10;
	top: 35%;
	left: 50%;
}
.pctPills {
	margin-right: 2px;
	margin-left: 2px;
	width: 55px;
}
.mainDisplay {
	width: 70%;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
}
.cardDivMain {
	display: flex;
}
.cardDiv {
	width: 50%;
	float: left;
	margin: 0 auto;
	padding: 10px;
}
@media screen and (max-width: 1600px) {
	.mainDisplay {
		width: 75%;
	}
	.cardDiv {
		width: 50;
	}
}
@media screen and (max-width: 900px) {
	.mainDisplay {
		width: 90%;
	}
	.cardDivMain {
		display: block;
	}
	.cardDiv {
		width: 100%;
	}
}
@media screen and (max-width: 600px) {
	.mainDisplay {
		width: 95%;
	}
	.cardDivMain {
		display: block;
	}
	.cardDiv {
		width: 100%;
	}
}
.infoCard {
	height: 100%;
	background-color: #3f424d;
	color: #cacaca;
}
.cardHeader {
	color: #D1A160 !important;
	font-weight: bold;
	font-size: 23px;
}
.infoSection {
	padding-top: 13px;
}
.userDataLabel {
	font-weight: bold;
	vertical-align: top;
}
.userDataInfo {
	text-align: right;
	vertical-align: top;
}
.userDataInput {
	float: right;
}
.priceSection {
	padding-bottom: 13px;
}
.priceInfo {
	float: right;
	font-size: 13px;
	font-style: italic;
}
.v-data-table {
	background-color: #BDBFC5 !important;
}
.logo {
	padding-left: 30px;
	padding-top: 15px;
}
.img-link {
	padding-left: 30px;
	padding-top: 15px;
	width: 15%
}
.walletConnection {
	vertical-align: middle;
	text-align: center;
}
</style>