<template>
	<div>
		<div class="txAlertBox">
			<b-alert :show="txAlertStatus" dismissable :variant="txAlertVariant" @dismissed="txAlertStatus=0" @dismiss-count-down="txCountChange" class="emfAlert"><span class="userDataLabel">{{txAlertMessage}}</span></b-alert>
		</div>
		<div class="loadingWindow" ref="loadWindow">
			<LoadSpinner :color="loadingColor" :height="loadingHeight" :width="loadingWidth"></LoadSpinner>
		</div>
		<b-container>
			<b-row>
				<b-col class="pageTitle">
					Mint Magic Fox NFTs
				</b-col>
			</b-row>
			<b-row col-sm ref="walletFunctions">
				<b-col sm="6">
					<b-img src="DemoFoxes.gif" rounded thumbnail fluid class="demoImage"/>
				</b-col>
				<b-col sm="6">
					<b-container class="dataBox">
						<b-row>
							<b-col class="dataLabel">Contract Address</b-col>
							<b-col class="dataValue"><a :href="contractLink" target="_new">{{ contractAddress }}</a></b-col>
						</b-row>
						<b-row>
							<b-col class="dataLabel">Contract Status</b-col>
							<b-col class="dataValue">{{ contractStatus }}</b-col>
						</b-row>
						<b-row>
							<b-col class="dataLabel">BNB Raised</b-col>
							<b-col class="dataValue">{{ bnbCollected }} BNB</b-col>
						</b-row>
						<b-row>
							<b-col class="dataLabel">NFT Count</b-col>
							<b-col class="dataValue">{{ nftCount }}/{{ maxTokenID }}</b-col>
						</b-row>
						<b-row style="margin-bottom:30px;">
							<b-col class="dataLabel">Mint Price</b-col>
							<b-col class="dataValue">{{ mintPrice }} BNB</b-col>
						</b-row>
						<b-row align-h="center" v-if="!isConnected">
							<b-col class="walletConnection">
								<b-button variant="warning" @click="connectWallet" v-if="!isConnected" style="margin-top: 20px;">Connect</b-button>
							</b-col>
						</b-row>
						<b-row v-if="!isConnected">
							<b-col class="walletConnection">
								<b-alert :show="true" style="margin-top:10px; background-color: #9a9a9a; color: #fafafa"><strong>Wallet:</strong> Not Connected</b-alert>
							</b-col>
						</b-row>
						<b-row align-h="center" v-if="isConnected">
							<b-col class="walletConnection">
								<b-button variant="danger" @click="disconnectWallet" v-if="isConnected" :disabled="disableButtons" style="margin-top: 20px; color: #fafafa;">Disconnect</b-button>
							</b-col>
						</b-row>
						<b-row v-if="isConnected">
							<b-col class="walletConnection">
								<b-alert :show="true" style="margin-top:10px; background-color: #1AD598; color: #06152b"><strong>Wallet:</strong> {{userWallet}}</b-alert>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="dataLabel">Mint QTY</b-col>
							<b-col class="dataValue">
								<b-form-select v-model="mintQTY" :options="mintQTYList" name="mintQTY" :required="true"></b-form-select>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="dataLabel">Total Price</b-col>
							<b-col class="dataValue">{{ formatNumber(totalNFTPrice) }} BNB</b-col>
						</b-row>
						<b-row>
							<b-col>
								<b-button @click="mintNFTs" :disabled="!isConnected || disableButtons" class="emfButton">Mint NFTs</b-button>
							</b-col>
						</b-row>
					</b-container>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
/* eslint-disable */
import LoadSpinner from 'vue-spinner/src/FadeLoader.vue'

import { nftABI } from './nftABI.js'

import Web3 from "web3"
import Web3Modal from "web3modal";
import WalletConnectProvider from '@walletconnect/web3-provider'

const web3RPC = "https://bsc-dataseed.binance.org/"; // mainnet
// const web3RPC = "https://data-seed-prebsc-1-s1.binance.org:8545"; // testnet

const web3Modal = new Web3Modal({
	cacheProvider: false,
	providerOptions: {
		walletconnect: {
			package: WalletConnectProvider,
			options: {
				/* mainnet */
				appName: "EMF-Minting",
				network: "binance",
				chainId: 56,
				rpc: {
					56: web3RPC,
				},
				/* testnet */
				/*
				appName: "EMF-Minting",
				network: "binance-testnet",
				chainId: 97,
				rpc: {
					97: web3RPC,
				},
				*/
			},
		},
	},
});

const w3Provider = new Web3(web3RPC);

//const nftAddress = "0xFBd3ebD572761be94e83ab883579d9f1328d310A";
const nftAddress = "0x3E0517F5D0915725A3949158c388Fc80145daD34";
const nftContract = new w3Provider.eth.Contract(nftABI, nftAddress);

export default {
	name: 'mintMagicFoxes',
	components: {
		LoadSpinner
	},
	data() {
		return {
			userWalletAddress: "",
			userWallet: "Not Connected",

			loadingColor: "#7732a8",
			loadingHeight: "10px",
			loadingWidth: "10px",
			provider: null,
			wcProvider: null,
			wcAccounts: null,

			contractAddress: null,
			contractLink: null,
			contractStatus: null,
			freezeContract: null,

			nftCount: 0,
			mintPrice: 0,
			maxTokenID: 0,
			maxBuy: 0,
			bnbCollected: 0,

			mintQTY: 1,

			mintQTYList: [],

			txAlertTime: 3,
			txAlertStatus: 0,
			txAlertVariant: "success",
			txAlertMessage: "Generic Message Box",

			isConnected: false,
			disableButtons: false,
			isMetaMask: false,
			walletStatus: "secondary"
		}
	},
	mounted() {
		this.getNFTInfo();
	},
	computed: {
		totalNFTPrice() {
			return this.mintPrice*this.mintQTY
		}
	},
	methods: {
		formatNumber(num) {
			return parseFloat(num).toFixed(3)
		},
		async connectWallet() {
			this.provider = await web3Modal.connect();
			this.wcProvider = new Web3(this.provider);
			this.wcAccounts = await this.wcProvider.eth.getAccounts()
			this.userWalletAddress = this.wcAccounts[0]
			this.userWallet = this.userWalletAddress.substring(0,5)+'....'+this.userWalletAddress.substring(37,42)
			this.settxAlertBox('success', 'Wallet Connected')
			this.isConnected = true
			this.provider.on("accountsChanged", (accounts) => {
			if(!accounts[0]) {
				this.clearUserInfo();
				this.stopTransaction();
			}
			});
			this.provider.on("connect", (info) => {
			});
			this.provider.on("disconnect", (message) => {
				this.clearUserInfo();
				this.stopTransaction();
			});
		},
		async disconnectWallet() {
			if(this.provider.killSession) {
				this.provider.killSession();
			}
			if(this.provider.disconnect) {
				this.provider.disconnect();
			}
			this.clearUserInfo();
			this.stopTransaction();
		},
		settxAlertBox(variant, message) {
			this.txAlertVariant = variant;
			this.txAlertMessage = message;
			this.txAlertStatus = this.txAlertTime;
		},
		txCountChange(dismissCountDown) {
			this.dismissCountDown = dismissCountDown
		},
		clearUserInfo() {
			this.userWalletAddress = ""
			this.userWallet = "Not Connected"
			this.isConnected = false
			this.walletStatus = "secondary"
			this.settxAlertBox('success', 'Wallet Disconnected')
		},
		startTransaction() {
			this.$refs.loadWindow.style.display = "inline";
			this.$refs.walletFunctions.style.opacity = "0.1";
		},
		stopTransaction() {
			this.$refs.loadWindow.style.display = "none";
			this.$refs.walletFunctions.style.opacity = "1";
		},
		async getNFTInfo() {
			this.contractLink = 'https://bscscan.com/address/'+nftAddress+'/';
			this.contractAddress = nftAddress.substring(0,5)+'....'+nftAddress.substring(37,42);
			this.nftCount = await nftContract.methods.nftCount().call();
			this.mintPrice = (await nftContract.methods.mintPrice().call())/(10 ** 18);
			this.maxBuy = await nftContract.methods.maxBuy().call();
			this.maxTokenID = await nftContract.methods.maxTokenID().call();
			this.bnbCollected = (await nftContract.methods.bnbCollected().call())/(10 ** 18);
			this.freezeContract = await nftContract.methods.freezeContract().call();
			if(this.freezeContract == true) {
				this.contractStatus = 'Inactive';
			}
			else {
				this.contractStatus = 'Active';
			}
			for(let nftQty=1; nftQty<=this.maxBuy; nftQty++) {
				this.mintQTYList.push({ value: nftQty, text: nftQty});
			}
		},
		async mintNFTs() {
			this.startTransaction();
			this.disableButtons = true;
			
			var totalPrice = ""+this.totalNFTPrice+"";
			var mintCost = this.wcProvider.utils.toWei(totalPrice, 'ether');
			
			const mint_nft_TX = {
				from: this.userWalletAddress,
				to: nftAddress,
				value: mintCost,
				data: nftContract.methods.mintNFT(this.userWalletAddress, this.mintQTY).encodeABI(),
			};

			await this.wcProvider.eth
				.sendTransaction(mint_nft_TX)
				.then((result) => {
					this.stopTransaction();
					this.disableButtons = false;
					this.settxAlertBox('success', 'Minting was successful');
					this.mintQTY = 1;
					this.getNFTInfo();
				})
				.catch((error) => {
					this.stopTransaction();
					this.disableButtons = false;
					this.settxAlertBox('danger', error.message);
					this.mintQTY = 1;
					this.getNFTInfo();
				});

		},
	}
}
</script>

<style>
.dataLabel {
	text-align: left;
	font-weight: bold;
}

.dataValue {
	text-align: right;
}
.txAlertBox {
	position: fixed;
	top: 0;
	text-align: center;
	width: 100%;
	z-index: 2;
	font-size: 30px;
}
.loadingWindow {
	display: none;
	position: fixed;
	z-index: 10;
	top: auto;
	left: auto;
}
.emfAlert {
	border-bottom: 1px solid #e4ebf3;
    border-radius: 50px;
    box-shadow: 1px 1px 7px #000;
	margin-top: 10px;
}
.emfButton {
	background-color: #3960e6;
    border-radius: 15px;
    box-shadow: 1px 1px 3px #000;
	font-family: Montserrat, sans-serif;
	color: #fff;
}
</style>